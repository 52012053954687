import React from 'react'
import { Container, Row, Col } from 'reactstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { withPrefix, Link } from 'gatsby';
import PatientenkarteiFeaturette from '../components/patientenkarteiFeaturette';
import VerschreibungFeaturette from '../components/VerschreibungFeaturette';
import DruckFeaturette from '../components/DruckFeaturette';
import { Pricing } from '../components/pricing';
import ASOesterreichImage from '../components/ASOesterreichImage';

const ASOesterreich = (props) => (
    <Layout>
        <SEO googleTitle="Medoboard - optimiert Ihre Praxis"
            googleDescription="Ihr Rundum-Sorglos-Paket: Patientenkartei, Honorarnotenmanagement, Warteliste, Patientenstammdatenverwaltung am Tablet"
            fbUrl="https://medoboard.com/ärztesoftware-österreich"
            fbImage={withPrefix('medoboard-fb.png')}
            fbTitle="Medoboard - optimiert Ihre Praxis"
            fbDescription="Ihr Rundum-Sorglos-Paket: Patientenkartei, Honorarnotenmanagement, Warteliste, Patientenstammdatenverwaltung am Tablet"
            keywords={[`medoboard`, `Ordinationssoftware`, `intelligent`, `einfach`, `Patientenakte`, `Arztsoftware`]} />
        <Container>
            <Row className="carousel">
                <Col md="12">
                    <ASOesterreichImage />
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <h2 className="featurette-heading">Ärztesoftware Österreich</h2>
                    <h4 className="text-muted mb-5">Medoboard bietet Ihnen ein Rundum-Sorglos-Paket:</h4>
                    <Row>
                        <Col lg="6" key="0" className="pt-4 pb-4">
                            <h5>Patientenkartei</h5>
                            <p>Die gesamte Patientenhistorie und alle <i>relevanten</i> Patientendaten auf einen Blick. Mit einem Klick.</p>
                            <Link to="/patientenkartei" className="btn btn-outline-primary">Mehr erfahren</Link>
                        </Col>
                        <Col lg="6" key="1" className="pt-4 pb-4">
                            <h5>Honorarnotenmanagement</h5>
                            <p>Eine einfache und schnelle Honorarnoten-Erstellung. Intelligente Vorlagen helfen bei der Verwaltung.</p>
                            <Link to="/finanzmanagement" className="btn btn-outline-primary">Mehr erfahren</Link>
                        </Col>
                    </Row>
                    <Row className="pt-4 pb-4">
                        <Col lg="6" key="2" className="pt-4 pb-4">
                            <h5>Warteliste</h5>
                            <p>Stammdaten-Erfassung durch den Patienten. Damit wird Ihre Sprechstundenhilfe entlastet.</p>
                            <Link to="/warteliste" className="btn btn-outline-primary">Mehr erfahren</Link>
                        </Col>
                        <Col lg="6" key="3" className="pt-4 pb-4">
                            <h5>Semantische Textvorlagen</h5>
                            <p>Semantische Textvorlagen optimieren das Datenmanagement Ihrer Patientenkartei.</p>
                            <Link to="/semantische-textvorlagen" className="btn btn-outline-primary">Mehr erfahren</Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr className="featurette-divider" />
            <Row>
                <Col md="12">
                    <PatientenkarteiFeaturette imageTitle="Patientenkartei" imageText="500x500"
                        heading="Alle Patienten..."
                        subheading="... mit einem Tastendruck" text="Der medizinische Alltag ist geprägt von komplexen Entscheidungen,
                        aufwendigen Diagnosen und zeitraubender Administration."/>
                </Col>
            </Row>
            <hr className="featurette-divider" />
            <Row>
                <Col md="12">
                    <VerschreibungFeaturette imageTitle="Verschreibung" featuretteAlign="featurette-right" />
                </Col>
            </Row>
            <hr className="featurette-divider" />
            <Row>
                <Col md="12">
                    <DruckFeaturette imageTitle="Druck" />
                </Col>
            </Row>
            <hr className="featurette-divider" />
            <Pricing />
        </Container>
    </Layout>
);

export default ASOesterreich;